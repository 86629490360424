var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "payer-groups-manage" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "manage-payer-groups",
            title: "Manage Payer Groups",
            "hide-footer": "",
            size: "md",
          },
          on: { hide: _vm.resetModal },
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-alert",
                {
                  attrs: { variant: "danger", dismissible: "" },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
              _c("div", [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c(
                    "div",
                    { staticClass: "flex-fill mr-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "group",
                            label: "Select a payer group to edit",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("multiselect", {
                                attrs: {
                                  openDirection: "bottom",
                                  options: _vm.payer_groups,
                                  placeholder: "Select a payer group",
                                  label: "name",
                                  searchable: true,
                                  "show-labels": false,
                                  "track-by": "payer_group_id",
                                },
                                on: {
                                  select: function ($event) {
                                    _vm.new_group = null
                                  },
                                },
                                model: {
                                  value: _vm.payer_group,
                                  callback: function ($$v) {
                                    _vm.payer_group = $$v
                                  },
                                  expression: "payer_group",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-fill ml-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "new_group",
                            label: "Or create a new group",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: "Give the new group a name" },
                            on: {
                              change: function ($event) {
                                _vm.payer_group = null
                              },
                            },
                            model: {
                              value: _vm.new_group,
                              callback: function ($$v) {
                                _vm.new_group = $$v
                              },
                              expression: "new_group",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.payer_group || _vm.new_group
                  ? _c(
                      "div",
                      [
                        !_vm.loading_payers
                          ? _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "payers",
                                  label: "Select Payers for Group",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("multiselect", {
                                      attrs: {
                                        openDirection: "bottom",
                                        options: _vm.selectable_payers,
                                        placeholder: "Select payers",
                                        label: "name",
                                        "close-on-select": false,
                                        "clear-on-select": false,
                                        multiple: true,
                                        "show-labels": false,
                                        "allow-empty": true,
                                        internalSearch: true,
                                        "track-by": "payer_id",
                                      },
                                      model: {
                                        value: _vm.selected_payers,
                                        callback: function ($$v) {
                                          _vm.selected_payers = $$v
                                        },
                                        expression: "selected_payers",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "mt-2",
                                staticStyle: { "text-align": "center" },
                              },
                              [_c("b-spinner")],
                              1
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "d-flex justify-content-end mt-4" }, [
                  _c(
                    "div",
                    [
                      _vm.payer_group
                        ? _c(
                            "b-button",
                            {
                              staticClass: "avo-basic-btn mr-2",
                              on: { click: _vm.deleteGroup },
                            },
                            [_vm._v(" Delete Group ")]
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn",
                          attrs: {
                            disabled:
                              _vm.payer_group == null &&
                              _vm.new_group != null &&
                              _vm.new_group == "",
                          },
                          on: { click: _vm.submitChanges },
                        },
                        [_vm._v(" Submit Changes ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }